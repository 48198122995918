<template>
    <r-e-dialog title="审核" class="layer-contract-detail" :visible.sync="dialogVisible" top="10vh" show-footer
                @click-submit="clickSubmit" @click-cancel="clickCancel">
        <div class="flex justify-between flex-warp" style="height: 300px;overflow:scroll;">
            <div v-for="(item,index) in imgList" :key="index">
                <div style="background: #f5f7fa;width: 225px;height: 150px;display: flex;justify-content: center;border: 1px solid #F1F1F3;border-radius: 5px;margin: 0 10px">
                    <el-image :src="createFullImageUrl(item)" style="display:flex;align-items: center;"
                              @click="look(createFullImageUrl(item))">
                        <div slot="error" style="font-size: 30px;">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                        <div slot="placeholder">加载中...</div>
                    </el-image>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="lookDialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </r-e-dialog>
</template>

<script>
    import {createFullImageUrl} from "@/components/Upload/index"
    import {getShopContractPage,contractAudit} from "@/api/shops-contract";
    import {MessageInfo, MessageError, MessageSuccess} from "@custom/message";
    export default {
        name: "layer-shenhe",
        data() {
            return {
                dialogVisible: false,
                imgList: [],
                lookDialogVisible: false,
                dialogImageUrl: '',
                formPublish: {
                    uuid: null
                },
                loadingOptions: {
                    lock: true,
                    text: "请您稍后...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
            }
        },
        props: {},
        methods: {
            openDialog(data) {
                let {uuid} = data;
                this.formPublish.uuid=uuid;
                let {contractPictures} = data;
                this.imgList = contractPictures !== null && contractPictures !== "" ? contractPictures.split(";").filter(item => item !== "") : [];
                this.dialogVisible = true;
            },
            createFullImageUrl(uuid) {
                return createFullImageUrl(uuid)
            },
            look(url) {
                this.lookDialogVisible = true;
                this.dialogImageUrl = url;
            },
            clickSubmit(){
                let that = this;
                const {uuid} = that.formPublish;
                this.$confirm('此操作将审核通过, 是否继续?', '注意', {type: 'warning'}).then(() => {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    contractAudit(uuid).then(res => {
                        loading.close();
                        MessageSuccess("审核通过");
                        that.clickCancel();
                    }).catch(err => loading.close());
                }).catch(() => {});
            },
            clickCancel(){
                this.dialogVisible = false;
                this.$emit("handleSearch");
                this.imgList = [];
                this.formPublish = {uuid: null};
            }
        }
    }
</script>

<style scoped>

</style>